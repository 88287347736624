
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiAppBar-root {
  background-color: blue;    
}

a.MuiButton-root {
  margin-left: 1em;
  margin-right: 1em;
}

.MuiToolbar-root {
  background-color: blue;
  color: white;
}

a.MuiMenuItem-root {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1el;
  padding-bottom: 1el;
  display: flex;
}

li.MuiMenuItem-root {
  display: flex;
}


.MuiPaper-root {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
}

header.MuiPaper-root {
  padding-top: 0;
  padding-bottom: 0;
}

a.MuiButton-root {
  display: inline-block;
}